import React from 'react';
import parse from 'html-react-parser'

const ServicesList = ({ slice }) => (
  <section
    id="services-list"
    className="section section--padded no-top-padding section--dark section--ctr service-list__section"
  >
    <div className="container">
      <div className="section--ctr__body service-list__intro">
        {parse(slice.primary.sl_intro.html)}
      </div>
      <div className="service-list">
        <div className="service__sizer" />
        {slice.items.map((item, i) => (
          <div key={i} className="service">
            <h3 className="service__title">
              {item.sl_service_group.document.data.sg_name}
            </h3>
            <div className="service__divider" />
            <div className="service__item-group">
              {item.sl_service_group.document.data.sg_services.map((service) => (
                <div
                  className="service__item"
                  key={service.sg_subservice}
                >
                  {service.sg_subservice}
                </div>
              ))}
            </div>
            <div className="service-list__expand">
              <span className="expand-text">Details</span> [<span className="expand-symbol">+</span>]
            </div>
          </div>
        ))}
      </div>
    </div>
  </section >
);

export default ServicesList;
