import * as React from 'react';
import logo from '../images/logo-sara-brown.png'

const Navbar = ({ data }) => (
  <>
    <div class="mat" />
    <div className="menu-overlay" >
      <div className="menu-overlay__inner">
        <div className="menu-overlay__close">x</div>
        <nav className="menu-overlay__nav">
          <a href="/" title="Home">Home</a>
          {data.config_menu.map((menu, i) => (
            <a
              key={i}
              href={menu.config_menu_link_url.url}
              title={menu.config_menu_link_html_title}
            >
              {menu.config_menu_link_text}
            </a>
          ))}
        </nav>
      </div>
    </div >
    <header id="nav" className="navigation">
      <div className="container">
        <div className="navigation__inner">
          <a href="/" title="Home" className="navigation__logo">
            <img src={logo} alt="Logo" />
          </a>
          <nav className="navigation__nav">
            {data.config_menu.map((menu, i) => (
              <a
                key={i}
                href={menu.config_menu_link_url.url}
                title={menu.config_menu_link_html_title}
              >
                {menu.config_menu_link_text}
              </a>
            ))}
          </nav>
          <div className="navigation__responsive-icon responsive-icon" id="trigger-responsive">
            <div className="responsive-icon__bar"></div>
            <div className="responsive-icon__bar"></div>
            <div className="responsive-icon__bar"></div>
          </div>
        </div>
      </div>
    </header>
  </>
)

export default Navbar;
