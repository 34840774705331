import React from 'react';

const PageHero = ({ slice }) => (
  <div id="hero" className={`hero hero--page hero--page${slice.primary.ph_style === '1' ? "" : "-2"}`}>
    {!slice.primary.ph_hide_underlay &&
      <div className="underlay underlay--fh underlay--right underlay--top underlay--2_3w underlay--faded">
        <img src={slice.primary.ph_underlay.url} alt={slice.primary.ph_underlay.alt} />
      </div>
    }
    <div className="container">
      <h6 className="hero__overline">{slice.primary.ph_overline}</h6>
      <h1 className="hero__heading">{slice.primary.ph_headline}</h1>
    </div>
  </div>
)

export default PageHero;
