import React from 'react';
import clsx from 'clsx'
import parse from 'html-react-parser';

const OffsetSection = ({ slice }) => {
	const data = slice.primary;
	const theme = data.os_theme;
	const image_side = data.os_text_side === 'right' ? 'left' : 'right';
	const btnTheme = theme === 'light' ? 'dark' : 'light';

	return (
		<section
			id={data.os_css_id}
			className={
				clsx(
					'section',
					'section--padded',
					'section--offset',
					`section--offset-${data.os_text_side}`,
					`section--${theme}`,
				)}
		>
			<div className={
				clsx(
					'underlay',
					'underlay--1_2w',
					'underlay--top',
					`underlay--${image_side}`,
					data.os_underlay_height
						? 'underlay--fh'
						: 'underlay--2_3h',
					data.os_vertical_overlap
					&& 'underlay--v_offset',
					theme === 'dark'
					&& data.os_fade
					&& 'underlay--faded',
				)}
			>
				<img src={data.os_image.url} alt={data.os_image.alt} />
				{data.os_fade && theme === 'light' && (
					<div className="underlay__mat underlay__mat--light" />
				)}
			</div>
			<div className={`container text--${data.os_text_side}`}>
				<div className={
					clsx(
						"section--offset__body",
						data.os_body_wide &&
						"section--offset__body--wide"
					)}>
					<h2 className={
						clsx(
							'section__heading',
							!data.os_is_accent_bar
							&& (data.os_accent_bar_pos
								? 'section__heading--tacc'
								: 'section__heading--lacc')
						)}
					>
						{data.os_css_id === 'impossible' ?
							parse(data.os_heading.replace(
								'impossible',
								'<span class="impossible">\
								<span class="impossible-line" style="transform: translate(0px, 0px);" />\
								</span>impossible</span>'
							))
							: data.os_heading}
					</h2>
					{parse(data.os_body.html)}
					{data.os_button_text && (
						<p>
							<a
								className={`btn btn--${btnTheme}`}
								href={data.os_button_link.url}
								title={data.os_button_html_title}
							>
								{data.os_button_text}
							</a>
						</p>
					)}
				</div>
			</div>
		</section >
	);
}

export default OffsetSection;
