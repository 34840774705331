import React from 'react';
import parse from 'html-react-parser';

const TwoColumn = ({ slice }) => (
  <section id="corporate-non-profit" className="section section--padded section--split equal-split section--dark">
    <div className="container">
      <div className="section--split__inner">
        <div className="section--split__left">
          <div className="event-type">
            <img class="fw event-type__image" src={slice.primary.tc_img1.url} />
            <h5 class="event-type__name">{slice.primary.tc_title1}</h5>
            <div class="event-type__divider" />
            {parse(slice.primary.tc_col1.html)}
          </div>
        </div>
        <div className="section--split__right">
          <div className="event-type">
            <img class="fw event-type__image" src={slice.primary.tc_img2.url} />
            <h5 class="event-type__name">{slice.primary.tc_title2}</h5>
            <div class="event-type__divider" />
            {parse(slice.primary.tc_col2.html)}
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default TwoColumn;
