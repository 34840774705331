import * as React from 'react';

const StepInside = ({ slice }) => (
  <section id="gallery" className="gallery-preview section section--dark">
    <div className="gallery-preview__heading">{slice.primary.si_heading}</div>
    <div className="container">
      <div className="gallery-inner">
        <div className="gallery-sizer" />
        {slice.items.map((item, i) => (
          <div
            key={i}
            className={`gallery-item gallery-item--${item.si_image_width}`}
          >
            <img src={item.si_image.url} />
          </div>
        ))}
      </div>
    </div>
    <div className="gallery-preview__footer">
      <p><a className="btn btn--light" href="/gallery" title="Gallery">Gallery</a></p>
    </div>
  </section >
);

export default StepInside;
