import React from 'react';

const Gallery = ({ slice }) => (
  <section id="gallery" className="gallery-full section section--dark">
    <div className="gallery-inner">
      <div className="gallery-sizer" />
      {slice.items.map((item, i) => (
        <div key={i} className={`gallery-item gallery-item--${item.gal_image_width}`}>
          <a href={item.gal_image.url}>
            <img src={item.gal_image.url} />
          </a>
        </div>
      ))}
    </div>
  </section>
)

export default Gallery;
