import React from 'react';

const ContactZone = ({ slice }) => (
  <section id="contact-zone" className="section section--padded no-top-padding section--split split-lean-left section--dark contact-zone">
    <div className="container">
      <div className="section--split__inner">
        <div className="section--split__left">
          {/* <p align="left" style={{ marginBottom: '2rem', border: '2px solid white', padding: '1rem' }}></p> */}
          <div className="contact-form">
            <form action="https://getform.io/f/c0142f08-92c8-48e6-9b39-96e0013439d0" method="POST">
              <div className="contact-form__row">
                <div className="contact-form__block">
                  <label>Name*</label>
                  <input type="text" name="uName" id="uName" required />
                </div>
                <div className="contact-form__block">
                  <label>Email*</label>
                  <input type="email" name="uEmail" id="uEmail" required />
                </div>
              </div>
              <div className="contact-form__row">
                <div className="contact-form__block">
                  <label>Phone*</label>
                  <input type="tel" name="uPhone" id="uPhone" required />
                </div>
                <div className="contact-form__block">
                  <label>Company</label>
                  <input type="text" name="uCompany" id="uCompany" />
                </div>
              </div>
              <div className="contact-form__row">
                <div className="contact-form__block">
                  <label>Message</label>
                  <textarea name="uMessage" id="uMessage" rows="5"></textarea>
                </div>
              </div>
              <div className="contact-form__footer">
                <input type="checkbox" name="contact_me_by_fax_only" value="1" style={{ display: 'none !important' }} tabIndex="-1" autoComplete="off" />
                <button type="submit" className="btn btn--light contact-form__button">Send</button>
              </div>
            </form>
          </div>
        </div>
        <div className="section--split__right">
          <h2 className="contact-zone__heading">Let's Talk</h2>
          <p>
            <a className="underlined" href="tel:6192434549">{slice.primary.cz_phone}</a><br />
            <a href="mailto: sara@sarabrownevents.com" className="underlined">{slice.primary.cz_email}</a>
          </p>
          <h2 className="contact-zone__heading">Get Social</h2>
          {slice.items.map((item, i) => (
            <a
              key={i}
              href={item.cz_social_link.url}
              target={item.cz_social_link.target}
            >
              <img
                src={item.cz_social_icon.url}
                style={{ width: 24, height: 24, marginRight: 7 }}
              />
            </a>
          ))}
        </div>
      </div>
    </div>
  </section >
);

export default ContactZone;
