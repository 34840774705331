import React from 'react';

const Brands = ({ slice }) => (
  <section id="brands" className="section section--padded no-top-padding section--dark section--ctr brands__section">
    <div className="container">
      <div className="section--ctr__body">
        <h2 className="section--ctr__heading">{slice.primary.br_heading}</h2>
        <div className="brands">
          {slice.items.map((item, i) => (
            <div key={i} className="brand">
              <div className="brand__inner">
                <img src={item.br_logo.url} alt={item.br_brand} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </section>
);

export default Brands;
