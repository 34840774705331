import React from 'react';

const Testimonial = ({ slice }) => (
  <section id="testimonial" className="testimonial testimonial--single section section--padded section--dark">
    <div className="container">
      <div className="testimonial__wrapper">
        <header className="testimonial__quote-icon">&ldquo;</header>
        <main className="testimonial__body">{slice.primary.tml_body}</main>
        <footer className="testimonial__footer">
          <div className="testimonial__author">{slice.primary.tml_author}</div>
          <div className="testimonial__org">{slice.primary.tml_org}</div>
        </footer>
      </div>
    </div>
  </section>
);

export default Testimonial;
