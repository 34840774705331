import React from 'react';
import parse from 'html-react-parser';

const LeaningSplit = ({ slice }) => (
  <section id="intro" className="section section--padded section--split split-lean-right section--dark">
    <div className="container">
      <div className="section--split__inner">
        <div className="section--split__left">
          {parse(slice.primary.lean_heading.html, {
            replace: (domNode) => {
              if (domNode.type === 'tag') {
                domNode.attribs.className = 'section--split__heading'
                return domNode
              }
            }
          })}
        </div>
        <div className="section--split__right">
          {parse(slice.primary.lean_body.html)}
        </div>
      </div>
    </div>
  </section>
);

export default LeaningSplit;
