import * as React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import parse from 'html-react-parser';

const Footer = () => {

  return (
    <StaticQuery
      query={graphql`
        query FooterQuery {
          prismicFooter {
            id
            data {
              footer_copyright
              footer_navigation {
                footer_link_html_title
                footer_link_text
                footer_link_url {
                  url
                  target
                }
              }
              footer_social {
                footer_social_link_url {
                  url
                  target
                }
                footer_social_link_html_title
                footer_social_icon {
                  alt
                  url
                }
              }
              footer_about {
                html
              }
            }
          }
        }
      `}
      render={data => (
        <footer className="footer section section--padded section--light">
          <div className="container">
            <div className="footer__columns">
              <div className="footer-column">
                <nav className="footer-nav">
                  <h6 className="footer-column__heading">Navigation</h6>
                  {
                    data.prismicFooter.data.footer_navigation.map((navLink, index) => {
                      return <a
                        key={index}
                        className="footer-nav__link"
                        href={navLink.footer_link_url.url}
                        target={navLink.footer_link_url.target} title={navLink.footer_link_html_title}>
                        {navLink.footer_link_text}
                      </a>
                    })
                  }
                </nav>
              </div>
              <div className="footer-column footer-social">
                <h6 className="footer-column__heading">Get Social</h6>
                {
                  data.prismicFooter.data.footer_social.map((network, index) => {
                    return <a
                      key={index}
                      href={network.footer_social_link_url.url}
                      title={network.footer_social_link_html_title}
                      target={network.footer_social_link_url.target}>
                      <img
                        src={network.footer_social_icon.url}
                        alt={network.footer_social_link_html_title}
                      />
                    </a>
                  })
                }
              </div>
              <div className="footer-column footer-about">
                <h6 className="footer-column__heading">Sara Brown Events</h6>
                {parse(data.prismicFooter.data.footer_about.html)}
              </div>
            </div>
            <div className="footer__copyright">&copy; {new Date().getFullYear()} {data.prismicFooter.data.footer_copyright}</div>
          </div>
        </footer>
      )}
    />);

}

export default Footer;
