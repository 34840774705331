import React, { Fragment } from 'react';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import { StaticQuery, graphql } from 'gatsby';
import {
  Navbar,
  Footer,
  Brands,
  ContactZone,
  Gallery,
  HomeHero,
  HowWeWork,
  LeaningSplit,
  OffsetSection,
  PageHero,
  ServicesList,
  StepInside,
  Testimonial,
  TwoColumn
} from '../components';
import '../style/styles.scss';


const PageSections = ({ slices }) =>
  slices.map((slice, index) => {
    const result = (() => {
      switch (slice.slice_type) {
        case 'home_hero': return <HomeHero slice={slice} />
        case 'offset_section': return <OffsetSection slice={slice} />
        case 'step_inside': return <StepInside slice={slice} />
        case 'testimonial': return <Testimonial slice={slice} />
        case 'page_hero': return <PageHero slice={slice} />
        case 'services_list': return <ServicesList slice={slice} />
        case 'how_we_work': return <HowWeWork slice={slice} />
        case 'two_column': return <TwoColumn slice={slice} />
        case 'leaning_split': return <LeaningSplit slice={slice} />
        case 'brands': return <Brands slice={slice} />
        case 'gallery': return <Gallery slice={slice} />
        case 'contact_zone': return <ContactZone slice={slice} />
        default:
      }
    })()
    return <Fragment key={index}>{result}</Fragment>;
  });

const Page = ({ pageContext }) => {
  const uid = pageContext.uid;
  const content = pageContext.content;
  const settings = pageContext.settings;

  return (
    <main id="page">
      <StaticQuery
        query={graphql`
          query SeoQuery {
            allSitePlugin(filter: {name: {in: ["gatsby-plugin-next-seo"]}}) {
              edges {
                node {
                  id
                  name
                  pluginOptions {
                    titleTemplate
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <GatsbySeo
            title={content.page_seo_title}
            titleTemplate={content.page_add_seo_suffix ? data.allSitePlugin.edges[0].node.pluginOptions.titleTemplate : "%s"}
            description={content.page_seo_description}
          />
        )}
      />
      <Navbar {...settings} />
      <PageSections slices={content.body} />
      <Footer />
    </main>
  );

}

export default Page;
