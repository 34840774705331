import * as React from 'react';
import hero_photo from '../images/gallery-photos/_BB67551.jpg';
import hero_circle from '../images/spinning-circle@2x.png'

const HomeHero = ({ slice }) => {
  return (
    <div id="hero" className="hero hero--home">
      <div className="underlay underlay--fh underlay--right underlay--2_3w underlay--faded">
        <img src={hero_photo} alt="Event" />
      </div>
      <div className="container">
        <h6 className="hero__overline">Sara Brown Events</h6>
        <h1 className="hero__heading">Virtual, Hybrid &amp; Live Event Management</h1>
        <div className="hero__btn-wrapper">
          <a className="btn btn--light hero__btn" href="/contact" title="Work With Us">Work With Us</a>
        </div>
      </div>
      <div className="spinning-wheel"><img src={hero_circle} alt="spinning slogan" id="slogan" /></div>
    </div>
  );
}

export default HomeHero;
